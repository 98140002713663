.App {
    font-family: 'Roboto', sans-serif;
    background-color: #f4f7f9;
    color: #333;
}


input, textarea, select, button {
    box-sizing: border-box;
}
