.candidate-form {
  overflow-y: scroll;
}

.candidate-form label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.candidate-form input,
.candidate-form select,
.candidate-form textarea {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #c7a44c;
}

button {
  padding: 0.75rem;
  background-color: #c7a44c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #c7a44c;
}

.main-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.form-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.candidate-form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-height: 80vh;
  overflow-y: scroll;
}

.input-group-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;

  .input-group-item {
    display: flex;
    flex-direction: column;
  }

  .upload-button {
    background-color: #c7a44c;
    color: white;
    display: flex;
    // padding: 10px;
    width: 100%;
    height: 100%;
    margin-top: 0px;
    margin-bottom: 4px;
    justify-content: center;
    align-items: center;
    column-gap: 3%;
    border-radius: 10px;
  }

  .hidden-file-input {
    display: none;
  }
}

.candidate-form label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.candidate-form input,
.candidate-form select,
.candidate-form textarea {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #c7a44c;
}

button {
  padding: 0.75rem;
  background-color: #c7a44c;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #c7a44c;
}

.cv-container {
  padding: 20px;
  background-color: #c7a44c;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Nouveaux styles ajoutés */
.form-field {
  width: 100%; /* Champs par défaut prenant toute la largeur */
}

.form-field-short {
  width: 48%; /* Champs pour Prénom et Nom réduits pour être alignés sur une ligne */
  margin: 0; /* Supprime la marge pour un alignement parfait */
  align-self: start; /* Alignement en haut pour une meilleure apparence */
  empty-cells: show; /* Affiche les cellules vides pour un meilleur alignement */
}

.form-field-large {
  width: 100%; /* Champs plus larges pour Biographie ou d'autres sections spécifiques */
}

.input-group {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Espacement entre les deux champs (par exemple, Prénom et Nom) */
}

.input-group div {
  flex: 1;
}

.display-on-tablet {
  display: none; /* Masque les éléments sur les écrans de taille tablette */

  @media (min-width: 768px) {
    display: block; /* Affiche les éléments sur les écrans de taille tablette */
  }
}
.skill-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.skill-item .form-field {
  flex: 1;
  margin-right: 10px;
}

.add-skill-button,
.remove-skill-button {
  background-color: #c7a44c;
  border: none;
  padding: 5px 10px;
  color: white;
  cursor: pointer;
}

.add-skill-button:hover,
.remove-skill-button:hover {
  background-color: #b3923e;
}